import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/alanfung/Documents/Projects/Code/gatsby-portfolio/src/templates/journal.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Finally finished my first song in Ableton. The interface has always been a steep learning curve for me but everything finally clicked. Not gonna lie, I cheated a little and downloaded an app to tell me what the harmonic chords were and I just kept jamming on it until this came out. I drew lots of inspiration from deadmau5, my favorite producer of all time. Also a little throw back trance vibe from the 90s.`}</p>
    <p>{`Hope you enjoy it as much as I enjoyed making it!`}</p>
    <iframe width="100%" height="300" scrolling="no" frameBorder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/815246392&color=%237c7c7c&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"></iframe>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      